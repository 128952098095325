.App {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.center-content {
  text-align: center;
}

.center-image {
  width: 300px; /* Adjust the width according to your image size */
  height: auto; /* Maintain aspect ratio */
  margin: 20px 0; /* Space around the image */
}
